import React, { useCallback, useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Range } from 'common/types'
import { DEFAULT_DATE_FORMAT } from 'constants/params'
import { AVERAGE_DAILY_FEE } from 'graphql/overview/queries'
import { DateTime } from 'luxon'
import { createTotalFeeChartOptions } from 'utils/CreateChartOptions'
import { generateFeeChartData } from 'utils/FeeChart'
import { getAverage } from 'utils/FormatTotalAmount'

import { ChartContainer, LoadingHolder, Text } from './styles'

function TotalFeeChart() {
  const feeChartOptions = createTotalFeeChartOptions()
  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ month: 6 }),
    to: DateTime.now(),
  })

  const { loading, data } = useQuery(AVERAGE_DAILY_FEE, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const feeChartData = useMemo(() => {
    if (data?.averageDailyFee) {
      return generateFeeChartData(data?.averageDailyFee)
    }
    return []
  }, [data])

  const handleFrom = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, from: date }))
  }, [])

  const handleTo = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, to: date }))
  }, [])

  const averageDailyFee = useMemo(
    () =>
      data?.averageDailyFee
        ? getAverage(data?.averageDailyFee, 'value').toFixed(4)
        : '0',
    [data],
  )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
        mt={1}
        mx={2}
      >
        <Text>Daily average fee: {averageDailyFee} MATIC</Text>
        <Box alignItems="center" display="flex" justifyContent="end">
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              format={DEFAULT_DATE_FORMAT}
              label="From"
              shouldDisableDate={day => (range?.to ? day > range?.to : false)}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: { width: '130px' },
                  variant: 'standard',
                },
              }}
              value={range?.from}
              onChange={handleFrom}
            />
            <Box mx={0.5}>-</Box>
            <DatePicker
              format={DEFAULT_DATE_FORMAT}
              label="To"
              shouldDisableDate={day =>
                range?.from ? day < range?.from : false
              }
              slotProps={{
                textField: {
                  size: 'small',
                  sx: { width: '130px' },
                  variant: 'standard',
                },
              }}
              value={range?.to}
              onChange={handleTo}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <ChartContainer>
        {loading ? (
          <LoadingHolder>
            <CircularProgress size={20} />
          </LoadingHolder>
        ) : (
          <>
            {feeChartData.length > 2 ? (
              <Chart
                chartType="AreaChart"
                data={feeChartData}
                height="100%"
                options={feeChartOptions}
              />
            ) : (
              <Box alignItems="center" display="flex" height={1}>
                <Text color={'colors.font.default'} mx={2}>
                  Data will be available in a day
                </Text>
              </Box>
            )}
          </>
        )}
      </ChartContainer>
    </>
  )
}

export default TotalFeeChart
