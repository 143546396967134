import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const ChartsWrapper = styled(Box)`
  grid-column: span 3;
  grid-row: span 5;
  background-color: #f7f9ff;
  border: 1px solid #e5edfd;
  border-radius: 8px;
  min-height: 450px;
`

export const Text = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

export const ChartWrapper = styled(Box)`
  background-color: white;
  border: 1px solid #e5edfd;
  border-radius: 16px;
  height: 32%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ChartContainer = styled(Box)`
  height: 150px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  div.google-visualization-tooltip {
    border: none;
  }
`

export const GridLimitsContainer = styled(Box)`
  top: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 16px;
  height: 100%;
  background-color: transparent;
  z-index: 3;
`

export const GridTitle = styled(Typography)`
  line-height: 11px;
  padding: 0 1px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 2px;
  background-color: white;
`

export const LoadingHolder = styled(Box)`
  height: 70px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ChartTitle = styled(Typography)`
  position: absolute;
  z-index: 1;
  top: -7px;
`
