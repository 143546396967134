import React from 'react'

import { Container } from './styles'

import { Subtitle, Text } from '../styles'

interface Props {
  averageTransactionTime: number
  currentStakeLimit?: number
  confirmedTransactionsPercent: number
  pendingQueries: number
  processedQueries: number
  totalQueries: number
  containersLeft: number
  containersTotal: number
}

function Average({
  averageTransactionTime = 0,
  currentStakeLimit = 0,
  confirmedTransactionsPercent = 0,
  pendingQueries = 0,
  processedQueries = 0,
  totalQueries = 0,
  containersLeft = 0,
  containersTotal = 0,
}: Props) {
  return (
    <>
      <Container px={2} py={2}>
        <Subtitle>Containers left:</Subtitle>
        <Text title={containersLeft?.toFixed()}>
          {containersLeft} <small>(from {containersTotal})</small>
        </Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Current stake limit</Subtitle>
        <Text title={currentStakeLimit?.toFixed()}>{currentStakeLimit}</Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Total queries</Subtitle>
        <Text title={`${totalQueries} queries`}>{totalQueries}</Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Queries processed / requested</Subtitle>
        <Text title={`${processedQueries} / ${pendingQueries}`}>
          {processedQueries} / {pendingQueries}
        </Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Average transaction processing time by Node</Subtitle>
        <Text title={`${averageTransactionTime?.toFixed()}}ms`}>
          {averageTransactionTime?.toFixed()}ms
        </Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Confirmed transactions progress</Subtitle>
        <Text title={`${confirmedTransactionsPercent?.toFixed(2)}%`}>
          {confirmedTransactionsPercent?.toFixed(2)}%
        </Text>
      </Container>
    </>
  )
}

export default Average
