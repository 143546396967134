import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { ChartLegend, EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createValidatorsBarChartOptions,
  generateValidatorsBarChartData,
} from 'utils/ValidatorsBarChart'

import map from 'lodash/map'

interface Props {
  chartDataRegistered?: Point[]
  chartDataClosed?: Point[]
}

const LegendItems = [
  { title: 'Registered', color: '#91A7D4' },
  { title: 'Closed', color: '#D92E35' },
]

function ValidatorsBarChart({
  chartDataRegistered = [],
  chartDataClosed = [],
}: Props) {
  const statsData = useMemo(
    () =>
      chartDataRegistered.length && chartDataClosed.length
        ? generateValidatorsBarChartData(chartDataRegistered, chartDataClosed)
        : [],
    [chartDataRegistered, chartDataClosed],
  )

  const xTicks = useMemo(
    () => map(chartDataRegistered, point => new Date(point?.day)),
    [chartDataRegistered],
  )

  const options = createValidatorsBarChartOptions(xTicks)

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {statsData.length ? (
        <Box height="100%" position="relative" width="100%">
          <ChartLegend legendItems={LegendItems} />
          <Chart
            chartType="ColumnChart"
            data={statsData}
            height="100%"
            options={options}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default ValidatorsBarChart
