import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { ChartLegend, EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createTokenPriceChartOptions,
  generateTokenPriceChartData,
} from 'utils/TokenPriceChart'

interface Props {
  chartDataPrice?: Point[]
  chartDataTVL?: Point[]
}

const LegendItems = [
  { title: 'Price', color: '#4D75B8' },
  { title: 'TVL', color: '#26B568' },
]
function TokenPriceChart({ chartDataPrice = [], chartDataTVL = [] }: Props) {
  const chartOptions = createTokenPriceChartOptions()

  const statsChartData = useMemo(
    () =>
      chartDataPrice?.length && chartDataTVL?.length
        ? generateTokenPriceChartData(chartDataPrice, chartDataTVL)
        : [],
    [chartDataPrice, chartDataTVL],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="end"
    >
      {statsChartData.length > 2 ? (
        <Box height="100%" position="relative" width="100%">
          <ChartLegend legendItems={LegendItems} />
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default TokenPriceChart
