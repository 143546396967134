import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createBridgedChartOptions,
  generateBridgetChartData,
} from 'utils/BridgetChart'

interface Props {
  chartData?: Point[]
  diff?: Point[]
}
function BridgedTokenChart({ chartData = [], diff = [] }: Props) {
  const chartOptions = createBridgedChartOptions()

  const statsChartData = useMemo(
    () =>
      chartData?.length && diff?.length
        ? generateBridgetChartData(chartData, diff)
        : [],
    [chartData, diff],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="end"
    >
      {chartData.length > 1 ? (
        <Box height="400px" width="100%">
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default BridgedTokenChart
