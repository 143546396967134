export enum Routes {
  ROOT = '/',
  STATS = '/daily_stats',
  ADMINS = '/admins',
  LOGIN = '/login',
  VERIFICATION = '/verification',
  REGISTER = '/register',
  RESET_PASSWORD = '/reset',
  CONFIRM_EMAIL = '/confirm-email',
  VALIDATORS = '/validators',
  PROVIDERS = '/providers',
  MODERATORS = '/moderators',
  NODES = '/nodes',
  SETTINGS = '/settings',
  QUERIES = '/queries',
  VAIDATIONS = '/validations',
  REWARDS = '/rewards',
  ANNOUNCEMENTS = '/announcements',
  QUESTS = '/quests',
  CLIENTS = '/clients',
}
