import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { ChartLegend, EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createUnstakeChartOptions,
  generateUnstakeChartData,
} from 'utils/UnstakeChart'

interface Props {
  earlyUnstakeNodeAmount?: Point[]
  earlyUnstakeValidatorAmount?: Point[]
}

const LegendItems = [
  { title: 'Validators', color: '#4D75B8' },
  { title: 'Nodes', color: '#26B568' },
]

function UnstakeStatsChart({
  earlyUnstakeNodeAmount = [],
  earlyUnstakeValidatorAmount = [],
}: Props) {
  const chartOptions = createUnstakeChartOptions()

  const statsChartData = useMemo(
    () =>
      earlyUnstakeValidatorAmount?.length && earlyUnstakeNodeAmount?.length
        ? generateUnstakeChartData(
            earlyUnstakeValidatorAmount,
            earlyUnstakeNodeAmount,
          )
        : [],
    [earlyUnstakeNodeAmount, earlyUnstakeValidatorAmount],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="end"
    >
      {statsChartData.length > 2 ? (
        <Box height="100%" position="relative" width="100%">
          <ChartLegend legendItems={LegendItems} style={{ top: 0 }} />
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
            width="100%"
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default UnstakeStatsChart
