import React, { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Box } from '@mui/system'
import { LoaderHolder } from 'common'
import { AdminRole, AlertMessage, Phase, PhaseStatus } from 'common/types'
import { PhaseStatusLabels } from 'constants/strings'
import { START_NEW_PHASE, STOP_PHASE } from 'graphql/phase/mutations'
import { CURRENT_PHASE } from 'graphql/phase/queries/currentPhase'
import { DateTime } from 'luxon'
import { getFormattedDate } from 'utils/Date'
import { getErrorMessage } from 'utils/Error'

import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

import {
  PhaseFilterContainer,
  PhaseInfoContainer,
  PhaseWrapper,
} from './styles'

import { useAppContext } from '../../../context/AppContext'
import { ConfirmStartPhaseModal, ConfirmStopPhaseModal } from '../Modals'

interface Props {
  currentPhase: Phase
  selectedPhase: Phase | null
  phases: Phase[]
  loading: boolean
  onSelectPhase: (phase: Phase) => void
  onAlert: (alert: AlertMessage) => void
}

function PhaseInfo({
  selectedPhase,
  onSelectPhase,
  phases,
  loading,
  onAlert,
  currentPhase,
}: Props) {
  const { meAdmin } = useAppContext()
  const [startPhaseConfirmModal, setStartPhaseConfirmModal] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })
  const [stopPhaseConfirmModal, setStopPhaseConfirmModal] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })

  const [stopPhase, { loading: stopPhaseLoading }] = useMutation(STOP_PHASE)
  const [startNewPhase, { loading: startNewPhaseLoading }] =
    useMutation(START_NEW_PHASE)

  const phasesByIndex = useMemo(() => keyBy(phases, 'index'), [phases])

  const handleSelectPhase = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      onSelectPhase(phasesByIndex[value as number])
    },
    [onSelectPhase, phasesByIndex],
  )

  const handleStopPhase = useCallback(async () => {
    try {
      await stopPhase({
        refetchQueries: [{ query: CURRENT_PHASE }],
      })
      onAlert({
        isOpen: true,
        text: 'Phase have been stopped successfully',
        alertColor: 'success',
      })
    } catch (error) {
      onAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
    setStopPhaseConfirmModal({ isOpen: false })
  }, [stopPhase, onAlert])

  const handleStartNewPhase = useCallback(async () => {
    try {
      await startNewPhase({
        refetchQueries: [{ query: CURRENT_PHASE }],
      })
      onAlert({
        isOpen: true,
        text: 'Phase have been started successfully',
        alertColor: 'success',
      })
    } catch (error) {
      onAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
    setStartPhaseConfirmModal({ isOpen: false })
  }, [onAlert, startNewPhase])

  const handleCloseStartPhaseModal = useCallback(() => {
    setStartPhaseConfirmModal({ isOpen: false })
  }, [])

  const handleOpenStartPhaseModal = useCallback(() => {
    setStartPhaseConfirmModal({ isOpen: true })
  }, [])

  const handleCloseStopPhaseModal = useCallback(() => {
    setStopPhaseConfirmModal({ isOpen: false })
  }, [])

  const handleOpenStopPhaseModal = useCallback(() => {
    setStopPhaseConfirmModal({ isOpen: true })
  }, [])

  const canStartNewPhase = useMemo(
    () =>
      selectedPhase?.id === currentPhase?.id &&
      currentPhase.status === PhaseStatus.COMPLETED &&
      meAdmin?.role === AdminRole.ADMIN,
    [currentPhase, selectedPhase, meAdmin],
  )

  // todo will be update with tuning phase
  const canStopCurrentPhase = useMemo(() => {
    const endDateTime = DateTime.fromISO(currentPhase?.endDate)
    const difference = endDateTime.diff(DateTime.now(), 'days').days
    return (
      selectedPhase?.id === currentPhase?.id &&
      currentPhase.status === PhaseStatus.ACTIVE &&
      meAdmin?.role === AdminRole.ADMIN &&
      difference < 2
    )
  }, [currentPhase, selectedPhase, meAdmin])

  return (
    <>
      <Box>
        <PhaseWrapper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 2,
            pt: 3,
          }}
        >
          {loading ? (
            <LoaderHolder sx={{ py: 4.5 }}>
              <CircularProgress size={37} />
            </LoaderHolder>
          ) : (
            <>
              {' '}
              <PhaseFilterContainer>
                {selectedPhase?.index && (
                  <FormControl>
                    <InputLabel id="select-phase">Phase</InputLabel>
                    <Select
                      defaultValue={selectedPhase?.index}
                      label="Phase"
                      labelId="select-phase"
                      size="small"
                      sx={{ borderRadius: '6px', mx: 0, mb: 1 }}
                      value={selectedPhase?.index}
                      onChange={handleSelectPhase}
                    >
                      {map(phases, (phase: Phase) => (
                        <MenuItem key={phase.id} value={phase.index}>
                          {phase.index === currentPhase.index
                            ? `${phase.index} (Current phase)`
                            : phase.index}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Box display="flex" gap={1.5} justifyContent="flex-end">
                  {canStopCurrentPhase && (
                    <Button
                      disabled={startNewPhaseLoading}
                      size="small"
                      sx={{ height: 38 }}
                      variant="contained"
                      onClick={handleOpenStopPhaseModal}
                    >
                      Stop current phase
                    </Button>
                  )}
                  {canStartNewPhase && (
                    <Button
                      disabled={startNewPhaseLoading}
                      size="small"
                      sx={{ height: 38 }}
                      variant="contained"
                      onClick={handleOpenStartPhaseModal}
                    >
                      Start New Phase
                    </Button>
                  )}
                </Box>
              </PhaseFilterContainer>
              <PhaseInfoContainer>
                <Box display="flex" flexDirection="column">
                  <Box>
                    Start Date: {getFormattedDate(selectedPhase?.startDate)}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box>
                    End Date: {getFormattedDate(selectedPhase?.endDate)}
                  </Box>
                </Box>
                <Box>
                  Status:{' '}
                  {selectedPhase?.status
                    ? PhaseStatusLabels[selectedPhase?.status]
                    : '--'}
                </Box>
              </PhaseInfoContainer>
            </>
          )}
        </PhaseWrapper>
      </Box>
      <ConfirmStartPhaseModal
        loading={startNewPhaseLoading}
        open={startPhaseConfirmModal.isOpen}
        onClose={handleCloseStartPhaseModal}
        onConfirm={handleStartNewPhase}
      />
      <ConfirmStopPhaseModal
        loading={stopPhaseLoading}
        open={stopPhaseConfirmModal.isOpen}
        onClose={handleCloseStopPhaseModal}
        onConfirm={handleStopPhase}
      />
    </>
  )
}

export default PhaseInfo
