import map from 'lodash/map'

import { generateFeeTooltipBlock } from './CreateChartOptions'

interface Point {
  key: string
  value: number
}

export function generateFeeChartData(points: Point[]) {
  return [
    ['Date', 'MATIC', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => [
      point?.key,
      point?.value,
      generateFeeTooltipBlock(point),
    ]),
  ]
}
