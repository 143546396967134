import React from 'react'

import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'

import { ButtonWrapper } from './styles'

interface Props {
  loading: boolean
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

function ConfirmStartPhaseModal({ open, onClose, onConfirm, loading }: Props) {
  return (
    <ModalWrapper
      open={open}
      title={'Are you sure you want to start a new phase?'}
      onClose={onClose}
    >
      <Typography sx={{ textAlign: 'justify', mb: 2 }}>
        Starting a new phase may have significant implications on the project
        and its participants. This action cannot be undone once initiated.
      </Typography>
      <Typography sx={{ textAlign: 'justify', mb: 2 }}>
        Please make sure you have carefully reviewed all necessary information
        and consulted with relevant stakeholders before proceeding.
      </Typography>

      <ButtonWrapper>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          fullWidth
          variant="contained"
          onClick={onConfirm}
        >
          Start
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default ConfirmStartPhaseModal
