import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { ChartLegend, EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createTokenStakedBarChartOptions,
  generateTokenPriceChartData,
} from 'utils/TokenStakedBarChart'

import map from 'lodash/map'

interface Props {
  chartDataStaked?: Point[]
  chartDataUnstaked?: Point[]
}

const LegendItems = [
  { title: 'Staked', color: '#91A7D4' },
  { title: 'Unstaked', color: '#D92E35' },
]
function TokenStakedBarChart({
  chartDataStaked = [],
  chartDataUnstaked = [],
}: Props) {
  const statsData = useMemo(
    () =>
      chartDataStaked.length && chartDataUnstaked.length
        ? generateTokenPriceChartData(chartDataStaked, chartDataUnstaked)
        : [],
    [chartDataStaked, chartDataUnstaked],
  )

  const xTicks = useMemo(
    () => map(chartDataStaked, point => new Date(point?.day)),
    [chartDataStaked],
  )

  const options = createTokenStakedBarChartOptions(xTicks)

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {statsData.length ? (
        <Box height="100%" position="relative" width="100%">
          <ChartLegend legendItems={LegendItems} />
          <Chart
            chartType="ColumnChart"
            data={statsData}
            height="100%"
            options={options}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default TokenStakedBarChart
