import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chart, GoogleChartWrapper } from 'react-google-charts'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { PointNodeByUser } from 'common/types'
import { ChartType } from 'constants/chartOptions'
import { generateColors } from 'utils/Colors'
import {
  createNodeByUserChartOptions,
  generateNodeByUserChartData,
} from 'utils/NodeStakedbyUserChart'

import { noop, orderBy } from 'lodash'
import forEach from 'lodash/forEach'

interface Props {
  chartType: ChartType
  chartData?: PointNodeByUser[]
}
function NodeStakedByUserChart({ chartData = [], chartType }: Props) {
  const [uptimeContainerId, setUptimeContainerId] = useState<string>('')
  const statsData = useMemo(() => {
    if (chartData?.length) {
      return chartType === ChartType.PIE_CHART
        ? generateNodeByUserChartData(
            orderBy(chartData, ['value'], ['asc']),
            chartType,
          )
        : generateNodeByUserChartData(chartData, chartType)
    }
    return []
  }, [chartData, chartType])

  const chartColors = useMemo(() => {
    if (!chartData?.length) {
      return ['#E7ECF6']
    }

    if (chartType === ChartType.PIE_CHART) {
      return generateColors({
        start: '#E7ECF6',
        base: '#91A7D4',
        end: '#5674B3',
        numSections: chartData?.length,
      })
    }
    return ['#91A7D4']
  }, [chartData, chartType])

  const options = createNodeByUserChartOptions(chartType, chartColors)

  const handleChartWrapper = (chartWrapper: GoogleChartWrapper) => {
    const containerId = chartWrapper?.getContainerId()
    setUptimeContainerId(containerId)
  }

  const handleUptimeChart = useCallback((uptimeContainerId: string) => {
    const container = document.getElementById(uptimeContainerId)

    forEach(container?.getElementsByTagName('text'), text => {
      if (text?.textContent?.trim() === '0') {
        text.remove()
      }
    })
  }, [])

  useEffect(() => {
    if (uptimeContainerId) {
      handleUptimeChart(uptimeContainerId)
    }
  }, [handleUptimeChart, uptimeContainerId])

  const renderLegend = useMemo(() => {
    return (
      <Box
        left="-20px"
        position="absolute"
        top="-26px"
        width="70px"
        zIndex={100}
      >
        <Box
          height="200px"
          sx={{
            borderRight: '1px solid #D0D0D9',
          }}
          width="48px"
        >
          {' '}
          <Typography
            color="#253566"
            fontSize={12}
            fontWeight={500}
            mb={1}
            pl={1}
          >
            Users
          </Typography>
        </Box>
        <Typography
          color="#253566"
          fontSize={12}
          fontWeight={500}
          pl={1}
          pt="2px"
          sx={{
            borderTop: '1px solid #D0D0D9',
          }}
        >
          Nodes
        </Typography>
      </Box>
    )
  }, [])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        height="100%"
        position="relative"
        sx={{ cursor: 'pointer' }}
        width="100%"
      >
        {chartType === ChartType.COLUMN_CHART && renderLegend}
        <Chart
          chartType={chartType}
          data={statsData}
          getChartWrapper={
            chartType === ChartType.COLUMN_CHART ? handleChartWrapper : noop
          }
          height="100%"
          options={options}
        />
      </Box>
    </Box>
  )
}

export default NodeStakedByUserChart
