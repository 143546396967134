import { dateFormatParserForChart } from './Date'

export const createUptimeChartOptions = () => ({
  curveType: 'function',
  fontFamily: 'Archivo',
  baselineColor: 'transparent',
  hAxis: {
    textPosition: 'none',
    gridlines: { count: 0 },
  },
  vAxis: {
    minValue: 0,
    maxValue: 100,
    ticks: [50, 90],
    gridlines: {
      color: '#e1a07c',
    },
    format: "#.#'%'",
    textPosition: 'none',
  },
  colors: ['#26B568', '#FFB406', '#E93940'],
  chartArea: { width: '100%', height: 100 },
  backgroundColor: 'transparent',
  legend: 'none',
  areaOpacity: 0.1,
  tooltip: { isHtml: true, trigger: 'visible' },
  // enableInteractivity: false,
})

export const createTotalFeeChartOptions = () => ({
  curveType: 'function',
  fontFamily: 'Archivo',
  baselineColor: 'transparent',
  hAxis: {
    textPosition: 'none',
    gridlines: { count: 0 },
  },
  vAxis: {
    ticks: [],
    format: "#.#'%'",
    textPosition: 'none',
  },
  colors: ['#4D75B8'],
  chartArea: { width: '100%', height: 130 },
  backgroundColor: 'transparent',
  legend: 'none',
  areaOpacity: 0.1,
  tooltip: { isHtml: true, trigger: 'visible' },
  // enableInteractivity: false,
})

export const generateFeeTooltipBlock = (point: any) =>
  `
		<div style="background-color: ${'#fff'}; padding: 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-size: 10px; padding: 5px; margin: 0;">${dateFormatParserForChart(
    point?.key,
  )}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${point?.value.toFixed(
    4,
  )} MATIC</p>
		</div>
		`

export const createTotalUptimeChartOptions = () => ({
  curveType: 'function',
  fontFamily: 'Archivo',
  baselineColor: 'transparent',
  hAxis: {
    textPosition: 'none',
    gridlines: { count: 0 },
  },
  vAxis: {
    minValue: 0,
    maxValue: 100,
    ticks: [50, 90],
    gridlines: {
      color: '#e1a07c',
    },
    format: "#.#'%'",
    textPosition: 'none',
  },
  colors: ['#26B568', '#FFB406', '#E93940'],
  chartArea: { width: '100%', height: 130 },
  backgroundColor: 'transparent',
  legend: 'none',
  areaOpacity: 0.1,
  tooltip: { isHtml: true, trigger: 'visible' },
  // enableInteractivity: false,
})

export const generateUptimeTooltipBlock = (point: any) =>
  `
		<div style="background-color: ${'#fff'}; padding: 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-size: 10px; padding: 5px; margin: 0;">${dateFormatParserForChart(
    point?.key,
  )}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${
    point?.value
  }%</p>
		</div>
		`
