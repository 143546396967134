import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'

import map from 'lodash/map'

import { getDateForCharts } from './Date'
import { getFormattedNumber } from './Number'

export const createTokenStakedChartOptions = () => ({
  ...commonChartOptions,
  colors: ['#4D75B8'],
  chartArea: { width: '98%', height: 350 },
})

export const generateTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${getDateForCharts(
    point?.day,
  )}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
		</div>
		`
}

export function generateTokenStakedChartData(points: Point[]) {
  return [
    ['Date', 'MATIC', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => {
      return [new Date(point?.day), point?.value, generateTooltipBlock(point)]
    }),
  ]
}
