import React from 'react'

import LensIcon from '@mui/icons-material/Lens'
import { Box } from '@mui/system'

import map from 'lodash/map'

type Legend = {
  title: string
  color: string
}

type Props = {
  style?: any
  legendItems: Legend[]
}

function ChartLegend({ legendItems, style }: Props) {
  return (
    <Box
      display="flex"
      sx={{
        color: 'rgba(91, 91, 91, 1)',
        fontSize: '12px',
        position: 'absolute',
        top: '10px',
        ...style,
      }}
    >
      {map(legendItems, legend => (
        <Box alignItems="center" display="flex" mr={4}>
          <LensIcon sx={{ mr: 0.5, fontSize: '10px', fill: legend.color }} />
          {legend.title}
        </Box>
      ))}
    </Box>
  )
}

export default ChartLegend
