import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import PageWrapper from 'common/pageWrapper'
import { ADMIN_STATS } from 'graphql/overview/queries'

import { Average } from './average'
import { Charts } from './charts'
import { Wrapper } from './styles'
import { Total } from './total'

interface AdminStats {
  allTimeNodes: number
  allTimeFee: number
  averageTransactionTime: number
  confirmedTransactionsPercent: number
  currentStakeLimit: number
  paidRewards: number
  pendingQueries: number
  pendingRewards: number
  processedQueries: number
  totalQueries: number
  totalStaked: number
  containersTotal: number
  containersLeft: number
}

function Overview() {
  const { data, loading } = useQuery(ADMIN_STATS, {
    pollInterval: 30000,
    fetchPolicy: 'network-only',
  })

  const {
    totalStaked,
    currentStakeLimit,
    paidRewards,
    pendingQueries,
    allTimeNodes,
    processedQueries,
    totalQueries,
    confirmedTransactionsPercent,
    averageTransactionTime,
    allTimeFee,
    containersTotal,
    containersLeft,
  }: AdminStats = useMemo(() => data?.adminStats || {}, [data])

  if (loading)
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100vh"
        justifyContent="center"
        width={1}
      >
        <CircularProgress />
      </Box>
    )
  return (
    <PageWrapper>
      <Wrapper>
        <Total
          allTimeFee={allTimeFee}
          allTimeNodes={allTimeNodes}
          paidRewards={paidRewards}
          totalStaked={totalStaked}
        />
        <Charts />
        <Average
          averageTransactionTime={averageTransactionTime}
          confirmedTransactionsPercent={confirmedTransactionsPercent}
          containersLeft={containersLeft}
          containersTotal={containersTotal}
          currentStakeLimit={currentStakeLimit}
          pendingQueries={pendingQueries}
          processedQueries={processedQueries}
          totalQueries={totalQueries}
        />
        {/* <ProviderSettings /> */}
      </Wrapper>
    </PageWrapper>
  )
}

export default Overview
