import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createTokenStakedChartOptions,
  generateTokenStakedChartData,
} from 'utils/TokenStakedChart'

interface Props {
  chartData?: Point[]
}
function TokenStakedChart({ chartData = [] }: Props) {
  const chartOptions = createTokenStakedChartOptions()

  const statsChartData = useMemo(
    () => (chartData?.length ? generateTokenStakedChartData(chartData) : []),
    [chartData],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="end"
    >
      {chartData.length > 1 ? (
        <Box height="400px" width="100%">
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default TokenStakedChart
