import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { AlertColor, Button, Snackbar } from '@mui/material'
import { Alert, Input } from 'common'
import { ALERT_DELAY } from 'constants/params'
import { emailValidationReg } from 'constants/validations'
import { useFormik } from 'formik'
import { REGISTER_ADMIN } from 'graphql/auth/mutations'
import { AuthContext } from 'index'
import { LoginFormLayout } from 'layouts'
import { Routes } from 'router/routes'
import Auth from 'services/Auth'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { InitialState } from './login.types'
import { Title } from './styles'

function Register() {
  const location = useLocation()
  const navigate = useNavigate()
  const { setAuthorized } = useContext(AuthContext)
  const urlParams = new URLSearchParams(location.search)
  const token: string = urlParams.get('token') as string
  const role: string = urlParams.get('role') as string

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const [registerAdmin] = useMutation(REGISTER_ADMIN)

  const validationSchema = yup.object({
    name: yup.string().required('Name is a required field'),
    email: yup
      .string()
      .email()
      .matches(emailValidationReg, 'Invalid email')
      .required('Email is a required field'),
    password: yup.string().min(4).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is a required field'),
  })

  const initialValues: InitialState = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  }

  const handleSubmit = async (values: InitialState) => {
    try {
      const { name, email, password } = values
      const result = await registerAdmin({
        variables: {
          name,
          email,
          password,
          token,
          role,
        },
      })
      if (result?.data?.registerAdmin) {
        Auth.handleAuth(
          result?.data.registerAdmin?.access,
          result?.data.registerAdmin?.refresh,
        )
        setAuthorized(true)
        navigate(Routes.ROOT)
      }
    } catch (error) {
      setAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
  })

  return (
    <>
      <LoginFormLayout>
        <Title>Sign up</Title>
        <Input
          error={formik.errors.name}
          name="name"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />

        <Input
          error={formik.errors.email}
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Input
          error={formik.errors.password}
          name="password"
          placeholder="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <Input
          error={formik.errors.confirmPassword}
          name="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
        />
        <Button
          sx={{ textTransform: 'initial' }}
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
      </LoginFormLayout>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Register
