import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { ChartLegend, EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createUptimeStatsChartOptions,
  generateUptimeStatsChartData,
} from 'utils/UptimeStatsChart'

interface Props {
  belowUptimeAmount?: Point[]
  belowUptimeDiffAllPercentage?: Point[]
}

const LegendItems = [
  { title: 'Amount of below uptime nodes', color: '#4D75B8' },
  { title: 'Percent of such nodes to all active nodes', color: '#26B568' },
]

function UptimeStatsChart({
  belowUptimeAmount = [],
  belowUptimeDiffAllPercentage = [],
}: Props) {
  const chartOptions = createUptimeStatsChartOptions()

  const statsChartData = useMemo(
    () =>
      belowUptimeAmount?.length && belowUptimeDiffAllPercentage?.length
        ? generateUptimeStatsChartData(
            belowUptimeAmount,
            belowUptimeDiffAllPercentage,
          )
        : [],
    [belowUptimeAmount, belowUptimeDiffAllPercentage],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="end"
    >
      {statsChartData.length > 2 ? (
        <Box height="100%" position="relative" width="100%">
          <ChartLegend legendItems={LegendItems} />
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default UptimeStatsChart
