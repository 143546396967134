import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, Snackbar } from '@mui/material'
import { Box } from '@mui/system'
import { Alert, SearchInput } from 'common'
import { DeleteModal, InviteModal } from 'common/modals'
import PageWrapper from 'common/pageWrapper'
import { AdminsTable } from 'common/tables'
import { Admin, AdminRole, AlertMessage } from 'common/types'
import {
  ALERT_DELAY,
  DEFAULT_SORT,
  ROWS_PER_PAGE_DEFAULT,
} from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { DELETE_ADMIN } from 'graphql/admins/mutations'
import { GET_ADMINS } from 'graphql/admins/queries'
import useTableSearch from 'hooks/useTableSearch'
import { getErrorMessage } from 'utils/Error'

import capitalize from 'lodash/capitalize'

interface Props {
  role?: AdminRole
}

function Admins({ role = AdminRole.ADMIN }: Props) {
  const { meAdmin } = useAppContext()

  const [isOpenInvite, setIsOpenInvite] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)

  const params = useMemo(
    () => ({
      page: 1,
      take: ROWS_PER_PAGE_DEFAULT,
      role,
      search: '',
      order: DEFAULT_SORT,
    }),
    [role],
  )

  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean
    entity: Admin | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const { loading, data, refetch } = useQuery(GET_ADMINS, {
    variables: { ...params },
  })

  const [deleteAdmin] = useMutation(DELETE_ADMIN)

  const adminsData: Admin[] = useMemo(() => data?.getAdmins?.data || [], [data])

  const adminsMeta = useMemo(() => data?.getAdmins?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        page: 1,
        role,
      })
      setCurrentPage(0)
    },
  })

  const handleOpenDeleteModal = useCallback((entity: Admin) => {
    setDeleteModal({ isOpen: true, entity })
  }, [])

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ isOpen: false, entity: null })
  }, [])

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ take: rowsPerPage, page: page + 1, search, role })
      setCurrentPage(page)
    },
    [refetch, role, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
        role,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [refetch, role, search],
  )

  const handleOpenInviteModal = useCallback(() => {
    setIsOpenInvite(true)
  }, [])

  const handleCloseInviteModal = useCallback(() => {
    setIsOpenInvite(false)
  }, [])

  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  const handleRefetch = useCallback(() => {
    refetch({ page: 1 })
    setCurrentPage(0)
  }, [refetch])

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteAdmin({
          variables: {
            id,
          },
        })

        await refetch({ ...params, take: rowsPerPage })
        setCurrentPage(0)
        setAlert({
          isOpen: true,
          text: `${capitalize(role)} successfully removed`,
          alertColor: 'success',
        })
        handleCloseDeleteModal()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
        handleCloseDeleteModal()
      }
    },
    [deleteAdmin, handleCloseDeleteModal, params, refetch, role, rowsPerPage],
  )

  return (
    <>
      <PageWrapper
        buttonText="Invite"
        pageTitle={`${capitalize(role)}s`}
        onClick={meAdmin?.isOwner ? handleOpenInviteModal : undefined}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          sx={{ mb: '10px', mx: '30px' }}
        >
          <Box sx={{ my: '10px' }}>
            <SearchInput
              endAdornment={<SearchIcon />}
              placeholder="Search"
              value={search}
              onChange={handleChangeSearch}
            />
          </Box>
          {loading ? (
            <Box
              alignItems="center"
              display="flex"
              height={1}
              justifyContent="center"
              width={1}
            >
              <CircularProgress />
            </Box>
          ) : (
            <AdminsTable
              count={adminsMeta?.itemCount ?? 0}
              data={adminsData}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onDelete={handleOpenDeleteModal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
        <Snackbar
          autoHideDuration={ALERT_DELAY}
          open={alert?.isOpen}
          onClose={() => setAlert({ isOpen: false })}
        >
          <Alert
            severity={alert?.alertColor}
            sx={{ width: '100%' }}
            onClose={() => setAlert({ isOpen: false })}
          >
            {alert?.text}
          </Alert>
        </Snackbar>
      </PageWrapper>
      <InviteModal
        open={isOpenInvite}
        role={role}
        onAlert={handleAlert}
        onClose={handleCloseInviteModal}
        onRefetch={handleRefetch}
      />
      {deleteModal?.entity && (
        <DeleteModal
          admin={{
            id: deleteModal?.entity?.id,
            name: deleteModal?.entity?.name,
          }}
          open={deleteModal?.isOpen}
          role={role}
          onClose={handleCloseDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </>
  )
}

export default Admins
