import { gql } from '@apollo/client'

export const UPDATE_SYSTEM_SETTINGS = gql`
  mutation UpdateSystemSettings(
    $cliVersion: String
    $dailyValidationsCap: Int
    $gasEmailsList: String!
  ) {
    updateSystemSettings(
      cliVersion: $cliVersion
      dailyValidationsCap: $dailyValidationsCap
      gasEmailsList: $gasEmailsList
    ) {
      cliVersion
      dailyValidationsCap
      gasEmailsList
    }
  }
`
