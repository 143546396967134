import React, { useMemo } from 'react'
import { useRoutes } from 'react-router-dom'

import { AdminRole } from 'common/types'
import { useAppContext } from 'context/AppContext'
import { MainLayout } from 'layouts'
import {
  AdminsPage,
  ExternalApiClientsPage,
  MnwDailyStatsPage,
  NewsPage,
  NodesPage,
  OverviewPage,
  ProvidersPage,
  QueriesPage,
  QuestsPage,
  RewardsPage,
  SettingsPage,
  ValidationsPage,
  ValidatorsPage,
} from 'pages'
import { Routes } from 'router/routes'

// todo need implement 404 page
export default function AuthorizedRouter() {
  const { meAdmin } = useAppContext()
  const routes = useMemo(() => {
    if (meAdmin?.role === AdminRole.MODERATOR)
      return [
        {
          element: <MainLayout />,
          children: [
            {
              index: true,
              element: <QuestsPage />,
            },
            {
              path: Routes.QUESTS,
              element: <QuestsPage />,
            },
            {
              path: '*',
              element: <QuestsPage />,
            },
          ],
        },
      ]

    return [
      {
        element: <MainLayout />,
        children: [
          { index: true, element: <OverviewPage /> },
          {
            path: Routes.STATS,
            element: <MnwDailyStatsPage />,
          },
          {
            path: Routes.ADMINS,
            element: <AdminsPage key="admins-page" />,
          },
          {
            path: Routes.MODERATORS,
            element: (
              <AdminsPage key="moderators-page" role={AdminRole.MODERATOR} />
            ),
          },
          {
            path: Routes.VALIDATORS,
            element: <ValidatorsPage />,
          },
          {
            path: Routes.PROVIDERS,
            element: <ProvidersPage />,
          },
          {
            path: Routes.NODES,
            element: <NodesPage />,
          },
          {
            path: Routes.SETTINGS,
            element: <SettingsPage />,
          },
          {
            path: Routes.QUESTS,
            element: <QuestsPage />,
          },
          {
            path: Routes.QUERIES,
            element: <QueriesPage />,
          },
          {
            path: Routes.VAIDATIONS,
            element: <ValidationsPage />,
          },
          {
            path: Routes.REWARDS,
            element: <RewardsPage />,
          },
          {
            path: Routes.ANNOUNCEMENTS,
            element: <NewsPage />,
          },
          {
            path: Routes.CLIENTS,
            element: <ExternalApiClientsPage />,
          },
          {
            path: '*',
            element: <OverviewPage />,
          },
        ],
      },
    ]
  }, [meAdmin])

  return useRoutes(routes)
}
