import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'

export const ButtonWrapper = styled(Box)({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
})

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
`
