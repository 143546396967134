import { gql } from '@apollo/client'

export const START_NEW_PHASE = gql`
  mutation StartNewPhase {
    startNewPhase {
      ok
    }
  }
`

export const STOP_PHASE = gql`
  mutation StopPhase {
    stopPhase {
      ok
    }
  }
`
export const RESTART_ALL_PHASE_PAYMENTS = gql`
  mutation restartAllPhasePayments {
    restartAllPhasePayments {
      ok
    }
  }
`
