import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import {
  CircularProgress,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Slider,
  Stack,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { LoaderHolder, SearchInput, SelectInput, SelectItem } from 'common'
import { UptimeModal } from 'common/modals'
import { AlertMessage, Range } from 'common/types'
import { SortInput, SortInputOrder } from 'common/ui/tableHeader/types'
import { DEFAULT_SORT, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { NODES } from 'graphql/nodes/queries'
import useTableSearch from 'hooks/useTableSearch'

import debounce from 'lodash/debounce'

import ValidationsModal from '../Modals/ValidationsModal'
import Table from '../Table'
import { NodeRewardStatus, Params } from '../types'

const params: Params = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  search: '',
  order: DEFAULT_SORT,
}

const MIN_DISTANCE_RANGE_SLIDER = 10

type Props = {
  range?: Range
  onAlert?: (alert: AlertMessage) => void
}
function RewardTableWrapper({ range, onAlert }: Props) {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)

  const [filters, setFilters] = useState<{ paid: NodeRewardStatus | null }>({
    paid: null,
  })
  const [rangeSliderValue, setRangeSliderValue] = useState<number[]>([0, 100])

  const [sort, setSort] = useState<SortInput | undefined>({
    column: 'unstakedAt',
    order: SortInputOrder.Desc,
  })

  const [validationsModal, setValidationsModal] = useState<{
    isOpen: boolean
    entity: string | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [uptimeModal, setUptimeModal] = useState<{
    isOpen: boolean
    entity: string | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        order: sort?.order?.toUpperCase(),
        uptimeFrom: rangeSliderValue?.[0],
        uptimeTo: rangeSliderValue?.[1],
        rewardStatus: filters?.paid || undefined,
        page: 1,
      })
      setCurrentPage(0)
    },
  })

  const debouncedFilterRef = useRef(
    debounce(
      (
        value: number[],
        search: string,
        rewardStatus?: NodeRewardStatus,
        take?: number,
        order?: SortInputOrder,
      ) => {
        refetch({
          take,
          uptimeFrom: value?.[0],
          uptimeTo: value?.[1],
          search,
          rewardStatus,
          page: 1,
          order: order ? order?.toUpperCase() : undefined,
        })
        setCurrentPage(0)
      },
      700,
    ),
  )

  const { data, loading, refetch } = useQuery(NODES, {
    variables: {
      ...params,
      page: currentPage + 1,
      take: rowsPerPage,
      search,
      rodeRewardStatus: filters?.paid,
      order: sort?.order?.toUpperCase(),
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [range])

  const handleChangeRangeSlider = useCallback(
    (event: Event, newValue: number | number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) {
        return
      }

      if (newValue[1] - newValue[0] < MIN_DISTANCE_RANGE_SLIDER) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], 100 - MIN_DISTANCE_RANGE_SLIDER)
          debouncedFilterRef.current(
            [clamped, clamped + MIN_DISTANCE_RANGE_SLIDER],
            search,
            filters?.paid || undefined,
            rowsPerPage,
            sort?.order,
          )
          setRangeSliderValue([clamped, clamped + MIN_DISTANCE_RANGE_SLIDER])
        } else {
          const clamped = Math.max(newValue[1], MIN_DISTANCE_RANGE_SLIDER)
          debouncedFilterRef.current(
            [clamped - MIN_DISTANCE_RANGE_SLIDER, clamped],
            search,
            filters?.paid || undefined,
            rowsPerPage,
            sort?.order,
          )
          setRangeSliderValue([clamped - MIN_DISTANCE_RANGE_SLIDER, clamped])
        }
      } else {
        debouncedFilterRef.current(
          newValue as number[],
          search,
          filters?.paid || undefined,
          rowsPerPage,
          sort?.order,
        )
        setRangeSliderValue(newValue as number[])
      }
    },
    [filters, rowsPerPage, search, sort],
  )

  const handleFilter = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      refetch({
        ...params,
        search,
        order: sort?.order?.toUpperCase(),
        uptimeFrom: rangeSliderValue?.[0],
        uptimeTo: rangeSliderValue?.[1],
        rewardStatus: (value as NodeRewardStatus) || undefined,
      })
      setFilters(prevState => ({
        ...prevState,
        paid: (value as NodeRewardStatus) || null,
      }))
      setCurrentPage(0)
    },
    [rangeSliderValue, refetch, search, sort],
  )

  const nodesData = useMemo(() => data?.nodes?.data || [], [data])
  const nodesMeta = useMemo(() => data?.nodes?.meta || {}, [data])

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({
        take: rowsPerPage,
        page: page + 1,
        search,
        order: sort?.order?.toUpperCase(),
        uptimeFrom: rangeSliderValue?.[0],
        uptimeTo: rangeSliderValue?.[1],
        rewardStatus: filters.paid || undefined,
      })
      setCurrentPage(page)
    },
    [filters, rangeSliderValue, refetch, rowsPerPage, search, sort],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
        order: sort?.order?.toUpperCase(),
        uptimeFrom: rangeSliderValue?.[0],
        uptimeTo: rangeSliderValue?.[1],
        rewardStatus: filters.paid || undefined,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [filters, rangeSliderValue, refetch, search, sort],
  )

  const handleChangeSortOrder = useCallback(
    (sort: SortInput) => {
      refetch({
        take: rowsPerPage,
        page: 1,
        search,
        order: sort?.order?.toUpperCase(),
        uptimeFrom: rangeSliderValue?.[0],
        uptimeTo: rangeSliderValue?.[1],
        rewardStatus: filters.paid || undefined,
      })
      setSort(sort)
      setCurrentPage(0)
    },
    [filters, rangeSliderValue, refetch, rowsPerPage, search],
  )

  const handleCopy = (copyContent: string, field: string) => {
    navigator.clipboard.writeText(copyContent)
    onAlert?.({
      isOpen: true,
      text: `The ${field} was copied`,
      alertColor: 'success',
    })
  }

  const handleOpenValidationsModal = useCallback((entity: string) => {
    setValidationsModal({ isOpen: true, entity })
  }, [])

  const handleCloseValidationsModal = useCallback(() => {
    setValidationsModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenUptimeModal = useCallback((entity: string) => {
    setUptimeModal({ isOpen: true, entity })
  }, [])

  const handleCloseUptimeModal = useCallback(() => {
    setUptimeModal({ isOpen: false, entity: null })
  }, [])

  return (
    <>
      <Box gap={3} sx={{ my: '16px', display: 'flex', alignItems: 'center' }}>
        <SearchInput
          endAdornment={<SearchIcon />}
          placeholder="Search by email"
          value={search}
          onChange={handleChangeSearch}
        />
        <FormControl>
          <SelectInput>Filter by</SelectInput>
          <SelectItem
            label="Filter"
            value={filters?.paid}
            onChange={handleFilter}
          >
            <MenuItem value={''}>
              <em>All</em>
            </MenuItem>
            <MenuItem value={NodeRewardStatus.PAID}>Paid</MenuItem>
            <MenuItem value={NodeRewardStatus.UNPAID}>Unpaid</MenuItem>
          </SelectItem>
        </FormControl>
        <Box sx={{ width: '250px' }}>
          <Typography fontSize={12}>Uptime filter</Typography>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography fontSize={12}>0%</Typography>
            <Slider
              disableSwap
              size="small"
              value={rangeSliderValue}
              valueLabelDisplay="auto"
              valueLabelFormat={(value: number) => `${value}%`}
              onChange={handleChangeRangeSlider}
            />
            <Typography fontSize={12}>100%</Typography>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <LoaderHolder>
          <CircularProgress />
        </LoaderHolder>
      ) : (
        <Table
          count={nodesMeta?.itemCount ?? 0}
          data={nodesData}
          page={currentPage}
          rowsPerPage={nodesMeta?.take ?? 0}
          sort={sort}
          onChangeSort={handleChangeSortOrder}
          onCopy={handleCopy}
          onOpenUptimeChart={handleOpenUptimeModal}
          onOpenValidations={handleOpenValidationsModal}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <ValidationsModal
        entity={validationsModal.entity}
        open={validationsModal.isOpen}
        onClose={handleCloseValidationsModal}
      />

      <UptimeModal
        nodeId={uptimeModal.entity}
        open={uptimeModal.isOpen}
        onClose={handleCloseUptimeModal}
      />
    </>
  )
}

export default RewardTableWrapper
