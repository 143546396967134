import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { LoaderHolder, ModalWrapper } from 'common'
import { VALIDATIONS } from 'graphql/validations/queries'

import Table from '../ValidationsTable'

interface Props {
  entity: string | null
  open: boolean
  onClose: () => void
}

function ValidationsModal({ open, onClose, entity }: Props) {
  const [page, setPage] = useState(0)

  const [loadValidations, { data, loading }] = useLazyQuery(VALIDATIONS)

  const fetchValidations = useCallback(
    (nodeId: string) => loadValidations({ variables: { filter: { nodeId } } }),
    [loadValidations],
  )

  useEffect(() => {
    if (entity) {
      fetchValidations(entity).then()
    }
  }, [entity, fetchValidations])

  const validationsData = useMemo(() => data?.validations?.data || {}, [data])
  const validationsMeta = useMemo(() => data?.validations?.meta || {}, [data])

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPage(page)
    },
    [],
  )
  return (
    <ModalWrapper
      open={open}
      style={{ maxWidth: '700px' }}
      title={'Validations'}
      onClose={() => {
        onClose()
      }}
    >
      {loading ? (
        <LoaderHolder>
          <CircularProgress />
        </LoaderHolder>
      ) : (
        <Table
          count={validationsMeta?.itemCount ?? 0}
          data={validationsData}
          page={page}
          rowsPerPage={validationsMeta?.take ?? 0}
          onPageChange={handlePageChange}
        />
      )}
    </ModalWrapper>
  )
}

export default ValidationsModal
