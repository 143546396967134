import React, { useCallback } from 'react'

import { useMutation } from '@apollo/client'
import { Input, ModalWrapper } from 'common'
import { AdminRole, AlertMessage } from 'common/types'
import { emailValidationReg } from 'constants/validations'
import { FormikProps, useFormik } from 'formik'
import { INVITE_ADMIN } from 'graphql/auth/mutations'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { StyledInputWrapper } from './styles'

export type InviteForm = {
  email: string
}
interface InitialValues {
  email: string
}
interface Props {
  open: boolean
  role?: AdminRole
  onClose: () => void
  onAlert: (alert: AlertMessage) => void
  onRefetch: () => void
}
function InviteModal({
  open,
  onClose,
  onAlert,
  onRefetch,
  role = AdminRole.ADMIN,
}: Props) {
  const [inviteAdmin, { loading }] = useMutation(INVITE_ADMIN)

  const initialValues: InviteForm = { email: '' }

  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .matches(emailValidationReg, 'Invalid email')
      .required(),
  })

  const {
    errors,
    handleSubmit,
    values,
    resetForm,
    handleChange,
  }: FormikProps<InitialValues> = useFormik<InitialValues>({
    initialValues,
    validationSchema,
    onSubmit: (values): Promise<void> => submit(values),
    validateOnChange: false,
  })

  const submit = useCallback(
    async (values: InitialValues) => {
      try {
        await inviteAdmin({
          variables: { ...values, role },
        })

        onAlert({
          isOpen: true,
          text: 'Invite have been sent successfully',
          alertColor: 'success',
        })
        onClose()
        resetForm()
        onRefetch()
      } catch (error) {
        onAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [inviteAdmin, onAlert, onClose, onRefetch, resetForm, role],
  )

  return (
    <ModalWrapper
      buttonText="Invite"
      disabled={loading}
      open={open}
      title={`Invite ${role} by email`}
      onClose={() => {
        onClose()
        resetForm()
      }}
      onSubmit={handleSubmit}
    >
      <StyledInputWrapper>
        <Input
          error={errors.email}
          name="email"
          placeholder="Email"
          value={values.email}
          onChange={handleChange}
        />
      </StyledInputWrapper>
    </ModalWrapper>
  )
}

export default InviteModal
