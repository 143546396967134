import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 30px;
`

export const Content = styled(Box)`
  flex-direction: column;
  width: 400px;
  margin-bottom: 30px;
`
