export enum NodeRewardStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export interface Params {
  filter?: { paid?: boolean }
  page: number
  take: number
  search: string
  from?: string
  to?: string
  phaseId?: string
  uptimeFrom?: number
  uptimeTo?: number
  order?: 'ACS' | 'DESC' | string
  rewardStatus?: NodeRewardStatus
}

export interface ValidatorRewardStats {
  paid: number
  toBePaid: number
  upcoming: number
}

export type BackendBalance = {
  mainAddress: string
  rewardBalance: string
  rewardGasBalance: string
  validateAddress: string
  validateGasBalance: string
}
