import { gql } from '@apollo/client'

export const ADMIN_STATS = gql`
  query AdminStats {
    adminStats {
      allTimeFee
      allTimeNodes
      averageTransactionTime
      confirmedTransactionsPercent
      currentStakeLimit
      paidRewards
      pendingQueries
      processedQueries
      totalQueries
      totalStaked
      containersTotal
      containersLeft
    }
  }
`

export const AVERAGE_DAILY_FEE = gql`
  query AverageDailyFee($from: DateTime, $to: DateTime) {
    averageDailyFee(from: $from, to: $to) {
      key
      value
    }
  }
`

export const AVERAGE_UPTIME = gql`
  query AverageUptime($from: DateTime, $to: DateTime) {
    averageUptime(from: $from, to: $to) {
      key
      value
    }
  }
`

export const NODE_VERSIONS_WITH_COUNT = gql`
  query NodeVersionsWithCount {
    nodeVersionsWithCount {
      cliData {
        count
        nodeVersion
      }
    }
  }
`
