import React from 'react'

import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'
import { ModalWrapper } from 'common'

import { ButtonWrapper } from './styles'

interface Props {
  loading: boolean
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

function ConfirmStopPhaseModal({ open, onClose, onConfirm, loading }: Props) {
  return (
    <ModalWrapper open={open} title={'Stop phase'} onClose={onClose}>
      <Box sx={{ textAlign: 'justify' }}>
        <Typography sx={{ mb: 2 }}>
          Are you sure you want to stop current phase?
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Please note that this action is irreversible.
        </Typography>
      </Box>
      <ButtonWrapper sx={{ mt: 3 }}>
        <Button
          disabled={loading}
          fullWidth
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button fullWidth variant="contained" onClick={onConfirm}>
          Stop phase
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default ConfirmStopPhaseModal
