import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import { ChartLegend, EmptyChartPlaceholder } from 'common'
import { Point } from 'common/types'
import {
  createValidationsChartOptions,
  generateValidationsChartData,
} from 'utils/ValidationsChart'

interface Props {
  validationsAmount?: Point[]
  validationsCap?: Point[]
}

const LegendItems = [
  { title: 'Cap', color: '#4D75B8' },
  { title: 'Amount of fulfilled validation per day', color: '#26B568' },
]

function ValidationsChart({
  validationsAmount = [],
  validationsCap = [],
}: Props) {
  const chartOptions = createValidationsChartOptions()

  const statsChartData = useMemo(
    () =>
      validationsAmount?.length && validationsCap?.length
        ? generateValidationsChartData(validationsCap, validationsAmount)
        : [],
    [validationsAmount, validationsCap],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="end"
    >
      {statsChartData.length > 2 ? (
        <Box height="100%" position="relative" width="100%">
          <ChartLegend legendItems={LegendItems} />
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default ValidationsChart
