import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chart, GoogleChartWrapper } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Range } from 'common/types'
import {
  DEFAULT_DATE_FORMAT,
  LOW_UPTIME_LINE,
  WARNING_UPTIME_LINE,
} from 'constants/params'
import { AVERAGE_UPTIME } from 'graphql/overview/queries'
import { DateTime } from 'luxon'
import { createTotalUptimeChartOptions } from 'utils/CreateChartOptions'
import { getAverage } from 'utils/FormatTotalAmount'
import { generateUptimeChartData } from 'utils/UptimeChart'

import forEach from 'lodash/forEach'
import map from 'lodash/map'
import round from 'lodash/round'

import {
  ChartContainer,
  GridLimitsContainer,
  GridTitle,
  LoadingHolder,
  Text,
} from './styles'

const gridlinesColors = ['#E93940', '#FFB406', '#26B568']

function TotalUptimeChart() {
  const [uptimeContainerId, setUptimeContainerId] = useState<string>('')
  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ month: 6 }),
    to: DateTime.now(),
  })

  const { loading, data } = useQuery(AVERAGE_UPTIME, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const uptimeChartData = useMemo(() => {
    if (data?.averageUptime) {
      return generateUptimeChartData(
        map(data?.averageUptime, (point: any) => ({
          ...point,
          value: round(point?.value, 2),
        })),
      )
    }
    return []
  }, [data])

  const averageUptime = useMemo(
    () => (data?.averageUptime ? getAverage(data.averageUptime, 'value') : 0),
    [data],
  )

  const uptimeChartOptions = createTotalUptimeChartOptions()

  const handleChartWrapper = (chartWrapper: GoogleChartWrapper) => {
    const containerId = chartWrapper?.getContainerId()
    setUptimeContainerId(containerId)
  }

  const handleUptimeChart = useCallback((uptimeContainerId: string) => {
    const container = document.getElementById(uptimeContainerId)
    let gridlineIndex = 0

    forEach(container?.getElementsByTagName('rect'), rect => {
      if (rect.getAttribute('height') === '1') {
        rect.setAttribute('fill-opacity', '0.2')
        rect.setAttribute('fill', gridlinesColors[gridlineIndex])
        gridlineIndex = +1
      }
    })
  }, [])

  useEffect(() => {
    if (uptimeContainerId) {
      handleUptimeChart(uptimeContainerId)
    }
  }, [handleUptimeChart, uptimeContainerId])

  const averageUptimeColor = useMemo(() => {
    if (averageUptime >= WARNING_UPTIME_LINE) {
      return gridlinesColors[2]
    }
    if (
      averageUptime < WARNING_UPTIME_LINE &&
      averageUptime >= LOW_UPTIME_LINE
    ) {
      return gridlinesColors[1]
    }
    return gridlinesColors[0]
  }, [averageUptime])

  const handleFrom = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, from: date }))
  }, [])

  const handleTo = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, to: date }))
  }, [])

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
        mt={1}
        mx={2}
      >
        <Box display="flex">
          <Text mr={1}>Average Network Uptime: </Text>
          <Text color={averageUptimeColor}>{averageUptime?.toFixed(2)}%</Text>
        </Box>
        <Box alignItems="center" display="flex" justifyContent="end">
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              format={DEFAULT_DATE_FORMAT}
              label="From"
              shouldDisableDate={day => (range?.to ? day > range?.to : false)}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: { width: '130px' },
                  variant: 'standard',
                },
              }}
              value={range?.from}
              onChange={handleFrom}
            />
            <Box mx={0.5}>-</Box>
            <DatePicker
              format={DEFAULT_DATE_FORMAT}
              label="To"
              shouldDisableDate={day =>
                range?.from ? day < range?.from : false
              }
              slotProps={{
                textField: {
                  size: 'small',
                  sx: { width: '130px' },
                  variant: 'standard',
                },
              }}
              value={range?.to}
              onChange={handleTo}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <ChartContainer>
        {loading ? (
          <LoadingHolder>
            <CircularProgress size={20} />
          </LoadingHolder>
        ) : (
          <>
            {uptimeChartData?.length > 2 ? (
              <>
                <GridLimitsContainer>
                  <GridTitle color={gridlinesColors[1]} mb="41px">
                    {WARNING_UPTIME_LINE}%
                  </GridTitle>
                  <GridTitle color={gridlinesColors[0]}>
                    {LOW_UPTIME_LINE}%
                  </GridTitle>
                </GridLimitsContainer>
                <Chart
                  chartType="AreaChart"
                  data={uptimeChartData}
                  getChartWrapper={handleChartWrapper}
                  height="100%"
                  options={uptimeChartOptions}
                />
              </>
            ) : (
              <Box alignItems="center" display="flex" height={1}>
                <Text color={'colors.font.default'} mx={2}>
                  Data will be available in a day
                </Text>
              </Box>
            )}
          </>
        )}
      </ChartContainer>
    </>
  )
}

export default TotalUptimeChart
