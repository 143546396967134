import React from 'react'

import { Container } from './styles'

import { Subtitle, Text } from '../styles'

interface Props {
  paidRewards: number
  totalStaked: number
  allTimeNodes: number
  allTimeFee: number
}

function Total({
  paidRewards = 0,
  totalStaked = 0,
  allTimeNodes = 0,
  allTimeFee = 0,
}: Props) {
  return (
    <>
      <Container px={2} py={2}>
        <Subtitle>Total rewards paid</Subtitle>
        <Text title={`${paidRewards.toFixed(2)} MNW`}>
          {paidRewards.toFixed(2)} MNW
        </Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Total staked</Subtitle>
        <Text title={`${totalStaked} MNW`}>{totalStaked} MNW</Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>All time nodes</Subtitle>
        <Text title={allTimeNodes?.toFixed()}>{allTimeNodes}</Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>All time fee</Subtitle>
        <Text title={`${allTimeFee?.toFixed(4)} MATIC`}>
          {allTimeFee?.toFixed(4)} MATIC
        </Text>
      </Container>
    </>
  )
}

export default Total
