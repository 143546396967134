import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'
import { DateTime } from 'luxon'

import find from 'lodash/find'
import map from 'lodash/map'

import { getFormattedNumber } from './Number'

export const createTokenPriceChartOptions = () => ({
  ...commonChartOptions,
  colors: ['#4D75B8', '#26B568'],
  chartArea: { width: '98%', height: 270 },
})

export const generateTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
		</div>
		`
}

export function generateTokenPriceChartData(
  points1: Point[],
  points2: Point[],
) {
  return [
    [
      'Date',
      'Price',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'TVL',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(points1, point => {
      const pointDiff = find(points2, diff => diff?.day === point?.day)
      return [
        new Date(point?.day),
        point?.value,
        generateTooltipBlock(point),
        pointDiff ? pointDiff.value : 0,
        pointDiff && generateTooltipBlock(pointDiff),
      ]
    }),
  ]
}
